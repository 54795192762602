import { NineInput } from "@9amhealth/wcl";
import type { Dispatch, FC, SetStateAction } from "react";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import parseNumber from "src/lib/parseNumber";
import translate from "src/lib/translate";
import { kgToPounds, poundsToKg } from "src/lib/unitConversion";
import QuestionnaireCubit from "src/state/QuestionnaireCubit/QuestionnaireCubit";
import type { QuestionnaireField } from "src/state/QuestionnaireStepCubit/QuestionnaireStepCubit";
import { useBloc } from "src/state/state";
import OnEvent from "src/ui/components/OnEvent/OnEvent";

const WeightInput: FC<{
  field: QuestionnaireField;
}> = (props) => {
  const name = props.field.id;
  const { getValues, setValue } = useFormContext();
  const kg = Number(getValues(name) ?? 0);
  const [, { saveValue }] = useBloc(QuestionnaireCubit);

  const inPounds = kgToPounds(kg);
  const [pounds, setPounds] = useState<number | string>(
    Math.round(Number(inPounds))
  );

  const handleChange =
    (setFn: Dispatch<SetStateAction<number | string>>) =>
    (fn: Event): void => {
      const { value } = fn.target as HTMLInputElement;
      const number = parseNumber(value);
      setFn(number);
    };

  useEffect(() => {
    const valueInKg = poundsToKg(parseFloat(pounds.toString()));

    // only save if the value is not 0 and a valid number
    if (typeof valueInKg === "number" && !isNaN(valueInKg)) {
      saveValue(name, valueInKg);
      setValue(name, valueInKg);
    } else {
      saveValue(name, undefined);
      setValue(name, undefined);
    }
  }, [pounds]);

  return (
    <div>
      <div>
        <OnEvent
          events={{
            [NineInput.customEvents.change]: handleChange(setPounds)
          }}
        >
          <nine-input
            mask="0[0][0]"
            placeholder={translate("pounds")}
            label=""
            unit="lbs"
            required="true"
            value={pounds ? `${pounds}` : ""}
            type="tel"
          ></nine-input>
        </OnEvent>
      </div>
    </div>
  );
};

export default WeightInput;
