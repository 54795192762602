import type { FC } from "react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import getErrorForField from "src/lib/getErrorForField";
import parseChoice from "src/lib/parseChoice";
import { removeQuestionnaireKeywordsFromText } from "src/lib/removeQuestionnaireKeywordsFromText";
import type {
  QuestionnaireField,
  QuestionnaireSelectChoice
} from "src/state/QuestionnaireStepCubit/QuestionnaireStepCubit";
import QuestionnaireStepCubit from "src/state/QuestionnaireStepCubit/QuestionnaireStepCubit";
import { useBloc } from "src/state/state";
import OnEvent from "src/ui/components/OnEvent/OnEvent";
import ErrorBox from "src/ui/components/StyledComponents/ErrorBox";
import Translate from "src/ui/components/Translate/Translate";

export interface QuestionnaireParsedSelectChoice
  extends QuestionnaireSelectChoice {
  affix?: string;
  clearOther: boolean;
  setVar?: { varName: string; varValue: string };
  groups?: string[];
  autoselectIfKey?: string;
}

export const MultipleChoiceList: FC<{
  field: QuestionnaireField;
  required: boolean;
}> = ({ field, required }) => {
  const [
    ,
    {
      handleChange: handleChangeCubit,
      updateCustomKeyList,
      checkChoiceForCustomKeys,
      triggerAutoContinue,
      questionnaireState
    }
  ] = useBloc(QuestionnaireStepCubit);

  const [selected, setSelected] = useState<Set<string>>(new Set());
  const canSelectMultiple = Boolean(field.properties?.allow_multiple_selection);
  const disabledDescription = field.properties?.disabled_description ?? "";
  const options: QuestionnaireParsedSelectChoice[] = parseChoice(
    field.properties?.choices
  );

  const formContext = useFormContext();
  const {
    setValue,
    register,
    formState: { errors },
    clearErrors
  } = formContext;

  register(field.id, { required });

  const { error } = getErrorForField({
    name: field.id,
    errors,
    labelText: ""
  });

  const updateFormValues = useCallback(
    (updated = selected, updateOptions: { save?: boolean } = {}) => {
      const { save = true } = updateOptions;
      // Update the form values
      options.forEach((option) => {
        setValue(option.id, updated.has(option.id));
      });

      const value = [...updated];
      setValue(field.id, value);

      handleChangeCubit(
        new CustomEvent("forceChange"),
        {
          [field.id]: value
        },
        {
          triggerAutoContinue: save
        }
      );

      const updatedOptions = options.filter((option) => updated.has(option.id));
      updateCustomKeyList({
        selected: updatedOptions,
        all: options
      });
    },
    [selected]
  );

  // set the selected values
  useEffect(() => {
    const updated = new Set(selected);

    const sv = questionnaireState.customFormData[field.id] ?? [];
    if (Array.isArray(sv)) {
      sv.forEach((v) => {
        if (options.find((option) => option.id === v)) {
          updated.add(v);
        }
      });
    }

    setSelected(updated);
    updateFormValues(updated, { save: false });
    triggerAutoContinue("initil");
  }, []);

  // a list of groups that are allowed to be selected, if empty, all groups are allowed
  const allowedGroups = useMemo(() => {
    // update groups
    const ubiquitousGroups: string[] = [];
    const selectedOptions = options.filter((option) => selected.has(option.id));
    const optionsWithGroups = selectedOptions.filter(
      (option) => typeof option.groups !== "undefined"
    );
    const selectedGroups = selectedOptions.reduce<string[]>(
      (acc, option) => [...acc, ...(option.groups ?? [])],
      []
    );

    const groupCount: Record<string, number> = {};
    selectedGroups.forEach((group) => {
      if (!groupCount[group]) groupCount[group] = 0;
      groupCount[group] = groupCount[group] + 1;

      if (groupCount[group] === optionsWithGroups.length)
        ubiquitousGroups.push(group);
    });

    return ubiquitousGroups;
  }, [selected]);

  const isOptionDisabled = useCallback(
    (option: QuestionnaireParsedSelectChoice, allowed: string[]) => {
      if (typeof option.groups === "undefined") return false;
      if (allowed.length === 0) return false;
      return !allowed.some((group) => option.groups?.includes(group));
    },
    []
  );

  const handleChange = useCallback(
    (
      e: CustomEvent<{
        checked: boolean;
        name: string;
      }>
    ) => {
      e.stopPropagation();

      const selectedId = e.detail.name;
      const { checked } = e.detail;
      const updated = new Set(selected);

      const isClearOption = options.find(
        (option) => option.id === selectedId
      )?.clearOther;

      const clearSelected = options
        .filter((option) => updated.has(option.id))
        .some((option) => option.clearOther);

      clearErrors();
      /**
       * we need to clear the other values if:
       * - we cannot select multiple options
       * - selectedOption has clear other property
       * - there is already clearOther property selected and we select other value
       */
      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
      if (checked && (isClearOption || !canSelectMultiple || clearSelected)) {
        options.forEach((option) => {
          updated.delete(option.id);
        });
      }

      // Update the selected values state
      if (checked) {
        updated.add(selectedId);
      } else {
        updated.delete(selectedId);
      }

      if (updated.size === 0 && !canSelectMultiple) {
        updated.add(selectedId);
      }

      // check if anything has changed
      const hasChanged =
        updated.size !== selected.size
          ? true
          : [...updated].some((value) => !selected.has(value));

      if (hasChanged) {
        setSelected(updated);
        updateFormValues(updated);
      }
    },
    [selected]
  );

  const inputType = useMemo(() => {
    if (canSelectMultiple) return "checkbox";
    return "radio";
  }, [canSelectMultiple]);

  return (
    <div>
      <nine-answer-item-list direction="vertical" style={{ textAlign: "left" }}>
        {options.map((option) => {
          const isSelected = selected.has(option.id);
          const label = removeQuestionnaireKeywordsFromText(option.label);
          const disabled = isOptionDisabled(option, allowedGroups);
          const showOption = checkChoiceForCustomKeys(option);

          if (!showOption.show) return null;

          return (
            <OnEvent
              key={option.id}
              events={{
                nineInputChange: handleChange
              }}
            >
              <nine-answer-item
                disabled={disabled ? "true" : "false"}
                value={option.label}
                name={option.id}
                checked={isSelected ? "true" : "false"}
                type={inputType}
                center="false"
                padding="true"
                tooltip={disabled ? disabledDescription : ""}
              >
                <strong>{label}</strong>
              </nine-answer-item>
            </OnEvent>
          );
        })}
      </nine-answer-item-list>

      {error && (
        <ErrorBox style={{ marginTop: 8 }} data-severity="error">
          <Translate
            msg={
              canSelectMultiple
                ? "question_required_select_multiple"
                : "question_required_select"
            }
          />
        </ErrorBox>
      )}
    </div>
  );
};

const MultipleChoiceInput: FC<{
  field: QuestionnaireField;
  required: boolean;
  asList?: boolean;
}> = ({ field, required }) => {
  return (
    <div>
      <MultipleChoiceList required={required} field={field} />
    </div>
  );
};

export default MultipleChoiceInput;
