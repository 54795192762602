type InputDataType = "name";

function capitalizeFirstLetter(word: string): string {
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
}

function formatName(name: string): string {
  const trimmedName = name.trim();
  const gaelicPrefixPattern = /^(o'|mc|mac)/i;
  const keepPrefixPattern = /^(ni|la)/;
  const doubleBarrelPattern = /-/;

  const words = trimmedName.split(" ");
  const formattedWords = words.map((word) => {
    const lower = word.toLowerCase();
    const gaelicMatch = gaelicPrefixPattern.exec(lower);

    if (gaelicMatch) {
      const [prefix] = gaelicMatch;
      const rest = word.slice(prefix.length);
      return capitalizeFirstLetter(prefix) + capitalizeFirstLetter(rest);
    }

    const keepPrefixMatch = keepPrefixPattern.exec(lower);
    if (keepPrefixMatch) {
      const [prefix] = keepPrefixMatch;
      const rest = word.slice(prefix.length);

      // its only a prefix if the next letter is uppercase
      if (rest.charAt(0).toUpperCase() === rest.charAt(0)) {
        return capitalizeFirstLetter(prefix) + capitalizeFirstLetter(rest);
      }
    }

    const doubleBarrelMatch = doubleBarrelPattern.exec(lower);
    if (doubleBarrelMatch) {
      const [first, second] = word.split("-");
      return capitalizeFirstLetter(first) + "-" + capitalizeFirstLetter(second);
    }

    return capitalizeFirstLetter(word);
  });

  return formattedWords.join(" ");
}

export default function parseInputData(
  type: InputDataType,
  value: string
): string {
  switch (type) {
    case "name":
      return formatName(value);
    default:
      return value;
  }
}
