import type { Dayjs } from "dayjs";
import { dateLocal } from "src/lib/date";
import type { FC, ReactElement } from "react";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import getErrorForField from "src/lib/getErrorForField";
import translate from "src/lib/translate";
import type { QuestionnaireField } from "src/state/QuestionnaireStepCubit/QuestionnaireStepCubit";
import MobileDateInputPicker from "src/ui/components/MobileDateInputPicker/MobileDateInputPicker";
import type { TranslationKey } from "src/types/translationKey";

export interface DatePickerProps {
  field: QuestionnaireField;
  onChange?: (field: QuestionnaireField, value: Dayjs) => void;
  required?: boolean;
  size?: "full" | "half";
  name?: string;
  initialValidation?: boolean;
  customFormVariables?: Record<string, unknown>;
}

const DateInput: FC<DatePickerProps> = (props): ReactElement => {
  const { field, required, name } = props;

  const {
    register,
    getValues,
    setValue,
    formState: { errors }
  } = useFormContext();

  const inpLabel = field.properties?.input_label as TranslationKey | undefined;
  const label = inpLabel ? translate(inpLabel) : translate("util_date_format");
  const formName = name ?? field.id;

  const { error, errorMessage } = getErrorForField({
    name: formName,
    errors,
    labelText: label
  });

  const isDataValid = (value?: Dayjs | string): boolean => {
    const day = value ? dateLocal(value) : undefined;
    if (day) {
      return day.isValid();
    }
    if (required) return false;
    return true;
  };

  register(formName, { required, validate: isDataValid });

  const setDate = (value: string): void => {
    setValue(formName, value);
  };

  const setFormVars = (day: string) => {
    const asDate = dateLocal(day);
    if (props.customFormVariables && field.properties?.ageField) {
      props.customFormVariables.user_age = Math.abs(
        asDate.diff(dateLocal(), "year")
      );
    }
  };

  const handleDateChange = (value: string | undefined) => {
    if (value) {
      setDate(value);
      setFormVars(value);
    }
    props.onChange?.(field, dateLocal(value));
  };

  const formValue: string = (getValues(formName) as string | undefined) ?? "";

  useEffect(() => {
    if (formValue) setFormVars(formValue);
  }, []);

  return (
    <MobileDateInputPicker
      max={(d) => d.endOf("day")}
      name={formName}
      label={label}
      value={formValue}
      required={required}
      error={error ? errorMessage : undefined}
      onChange={handleDateChange}
    />
  );
};

export default DateInput;
