import i18next from "i18next";
import type { FieldError, FieldErrors, FieldErrorsImpl } from "react-hook-form";

export enum FieldErrorType {
  Validation = "validate"
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ErrorProxy = Partial<FieldErrorsImpl<Record<string, any>>>;

export default function getErrorForField(params: {
  name: string;
  labelText: string;
  errors: FieldErrors;
}): {
  error: FieldError | undefined;
  errorMessage: string;
} {
  const { name, errors, labelText } = params;
  let error: FieldError | undefined = errors[name]
    ? (errors[name] as FieldError)
    : undefined;

  const namePath = name.split(".");
  let tmpErrors = { ...errors } as ErrorProxy;
  for (const part of namePath) {
    if (tmpErrors[part] && typeof tmpErrors[part] === "object") {
      tmpErrors = tmpErrors[part] as ErrorProxy;
    }
    if (tmpErrors.type) {
      error = tmpErrors as unknown as FieldError;
    }
  }
  let errorMessage = error?.message ? i18next.t(error.message) : "";

  if (!errorMessage && typeof error === "string") {
    errorMessage = i18next.t(error, {
      label: labelText || i18next.t("this_field_")
    });
  }

  if (error && !errorMessage) {
    errorMessage = i18next.t(
      error.type === FieldErrorType.Validation
        ? "invalid_field"
        : "required_field",
      {
        label: labelText || i18next.t("this_field_")
      }
    );
  }

  return {
    error,
    errorMessage
  };
}
