import styled from "@emotion/styled";
import type { FC } from "react";
import React, { useCallback, useEffect } from "react";

const ButtonEl = styled.button`
  height: 0;
  width: 0;
  opacity: 0;
  display: block;
  overflow: hidden;
  /* scroll offset */
  position: relative;
  top: -100px;
  left: -100vw;
`;

// returns the position of the element absolute to the document
const getElementScrollPosition = (
  el: HTMLElement,
  parentOffset = 0
): number => {
  const elOffset = el.offsetTop + parentOffset;
  const parent = el.offsetParent as HTMLElement | null;
  if (parent) {
    return getElementScrollPosition(parent, elOffset);
  }
  return elOffset;
};

const ScrollToMe: FC = () => {
  const btnRef = React.useRef<HTMLButtonElement>(null);

  const scrollToMe = (): void => {
    const el = btnRef.current;

    if (!el) {
      return;
    }

    el.scrollIntoView({
      block: "start",
      inline: "start"
    });

    const closestElHeightSetter = el.closest("[data-el-height]");
    if (closestElHeightSetter) {
      // add [data-el-active], for 1s
      closestElHeightSetter.setAttribute("data-el-active", "true");
      setTimeout(() => {
        closestElHeightSetter.removeAttribute("data-el-active");
      }, 1000);
    }
  };

  const setStyles = useCallback(() => {
    if (!btnRef.current) return;

    const btn = btnRef.current;
    const closestElHeightSetter = btn.closest("[data-el-height]");

    const elHeight = closestElHeightSetter
      ? closestElHeightSetter.clientHeight
      : 0;

    const btnPos = getElementScrollPosition(btn);
    const docHeight = document.body.scrollHeight;
    const rootHeight = document.querySelector("#root")?.clientHeight ?? 0;
    const scrollHeight = docHeight > rootHeight ? docHeight : rootHeight;

    const elHeightPercent = (elHeight / scrollHeight) * 100;
    const elOffsetPercent = (btnPos / scrollHeight) * 100;

    const styles = {
      "--pos-in-document-percent": `${elOffsetPercent}%`,
      "--el-height-percent": `${elHeightPercent}%`
    };

    Object.entries(styles).forEach(([k, v]) => {
      btn.style.setProperty(k, v);
    });
  }, [btnRef]);

  useEffect(() => {
    scrollToMe();
    setStyles();

    const resizeObserver = new ResizeObserver(setStyles);
    const root = document.querySelector("#root");
    if (root) {
      resizeObserver.observe(root);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [btnRef.current]);

  return (
    <ButtonEl ref={btnRef} className="scroll-to-me" onClick={scrollToMe}>
      Scroll
    </ButtonEl>
  );
};

export default ScrollToMe;
