import type { InputChangeDetails } from "@9amhealth/wcl";
import styled from "@emotion/styled";
import type { FC } from "react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { APP_BREAKPOINT } from "src/constants/layout";
import { stateCodeToNameMap } from "src/constants/stateCodeToNameMap";
import getErrorForField, { FieldErrorType } from "src/lib/getErrorForField";
import parseNumber from "src/lib/parseNumber";
import translate from "src/lib/translate";
import QuestionnaireCubit from "src/state/QuestionnaireCubit/QuestionnaireCubit";
import type { QuestionnaireField } from "src/state/QuestionnaireStepCubit/QuestionnaireStepCubit";
import UserPreferencesCubit from "src/state/UserPreferencesCubit/UserPreferencesCubit";
import { useBloc } from "src/state/state";
import OnEvent from "src/ui/components/OnEvent/OnEvent";

const StyledInput = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 1.6rem;

  &:last-of-type {
    margin: 0;
  }

  @media screen and (min-width: ${APP_BREAKPOINT}px) {
    margin: 0;
  }

  & span {
    font-weight: 700;
  }
`;

export const StateCode = styled.div`
  border: 1px solid gray;
  border-radius: 0;
  line-height: 1.7;
  padding: 3px 8px 0;
`;

const ZipCodeInput: FC<{
  field: QuestionnaireField;
  label?: string;
  placeholder?: string;
  required?: boolean;
}> = ({ field, label, required }) => {
  const name = field.id;
  const {
    getValues,
    setValue,
    clearErrors,
    setError,
    register,
    formState: { errors }
  } = useFormContext();
  const [, { customFormVariables, saveValue }] = useBloc(QuestionnaireCubit);
  const [, { checkZipCode }] = useBloc(UserPreferencesCubit);
  const [stateCode, setStateCode] = useState("");
  const isMounted = useRef(true);

  register(name, { required });

  const { errorMessage } = getErrorForField({
    name,
    errors,
    labelText: label ?? ""
  });

  const [zipCode, setZipCode] = useState<string | undefined>(
    (getValues(name) as string | undefined) ?? ""
  );

  const setValues = (value?: string): void => {
    saveValue(name, value);
    setValue(name, value);
  };

  const checkCode = async (value: string): Promise<void> => {
    clearErrors(name);
    const currentValue = getValues(name) as string | undefined;

    // do not look up zip code if it is the same as the current value
    if (currentValue === value && stateCode) return;

    const data = await checkZipCode(value);
    if (!isMounted.current) return;

    const stateName = stateCodeToNameMap[data?.state ?? ""];

    if (data && stateName) {
      setStateCode(data.state);
      setValues(value);
      customFormVariables.user_state = stateName;
    } else {
      setValues("");
      setStateCode("");

      customFormVariables.user_state = undefined;

      setError(name, {
        type: FieldErrorType.Validation,
        message: translate("error.zip")
      });
    }
  };

  const onChangeHandle = useCallback(
    (fn: CustomEvent<InputChangeDetails>): void => {
      const value = parseNumber(fn.detail.value);
      setZipCode(value);

      // set error when zip code length not 5 characters
      if (!value || value.length !== 5) {
        setValues("");
        setStateCode("");
        customFormVariables.user_state = undefined;
      } else {
        void checkCode(value);
      }
    },
    [setValues, setError, clearErrors, customFormVariables, label]
  );

  useEffect(() => {
    isMounted.current = true;
    const value = getValues(name) as string | undefined;
    if (value) void checkCode(value);

    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <StyledInput>
      <OnEvent
        events={{
          nineInputChange: onChangeHandle
        }}
      >
        <nine-input
          label={label}
          error={errorMessage}
          value={`${zipCode}`}
          required={required ? "true" : "false"}
          name={name}
          mask="00000"
          lazy="false"
          scroll-into-view-on-focus
          type="tel"
        >
          <div slot="decoration-end">
            {stateCode && <StateCode>{stateCode}</StateCode>}
          </div>
        </nine-input>
      </OnEvent>
    </StyledInput>
  );
};

export default ZipCodeInput;
