import styled from "@emotion/styled";
import clsx from "clsx";
import type {
  FormEventHandler,
  ForwardedRef,
  PropsWithChildren,
  ReactElement,
  SyntheticEvent
} from "react";
import React, { forwardRef, useEffect } from "react";
import type {
  DefaultValues,
  FieldErrors,
  FieldValues,
  Path,
  UseFormReturn
} from "react-hook-form";
import { FormProvider, useForm } from "react-hook-form";
import translate from "src/lib/translate";
import type { LoadingKey } from "src/state/LoadingCubit/LoadingCubit";
import type ShipmentAddressCubit from "src/state/ShipmentAddressCubit/ShipmentAddressCubit";
import type { ShipmentAddressState } from "src/state/ShipmentAddressCubit/ShipmentAddressState";
import Loader from "src/ui/components/Loader/Loader";

const StyledForm = styled.form`
  width: 100%;

  &.grow {
    height: 100%;
    display: flex;
  }
`;

interface Props<T extends FieldValues> {
  onSubmit?: (data: T, events: UseFormReturn<T>) => void;
  onError?: (errors: FieldErrors<T>) => void;
  onChange?: (event: SyntheticEvent, data: T, events: UseFormReturn<T>) => void;
  defaultValues?: DefaultValues<T>;
  loadingKey?: LoadingKey;
  className?: string;
  grow?: boolean;
  hideLoading?: boolean;
  bloc?: ShipmentAddressCubit;
}

function Form<T extends FieldValues>(
  props: PropsWithChildren<Props<T>>,
  ref: ForwardedRef<HTMLFormElement>
): ReactElement {
  const methods = useForm<T>({
    defaultValues: props.defaultValues
  });

  const handleChange = (event: SyntheticEvent): void => {
    props.onChange?.(event, methods.getValues(), methods);
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e): void => {
    void methods
      .handleSubmit((data) => {
        props.onSubmit?.(
          {
            ...props.defaultValues,
            ...data
          },
          methods
        );
      })(e)
      .then(() => {
        if (Object.keys(methods.formState.errors).length > 0) {
          props.onError?.(methods.formState.errors);
        }
      });
  };

  useEffect(() => {
    props.bloc?.addChangeListener(({ nextState }) => {
      const { errors } = nextState as unknown as ShipmentAddressState;
      for (const e of errors) {
        methods.setError(e.field as Path<unknown>, {
          message: translate(e.message)
        });
      }
    });
  }, [props.bloc]);

  const loaderActive = props.hideLoading ? !props.hideLoading : undefined;

  return (
    <FormProvider<T> {...methods}>
      <Loader
        grow={props.grow}
        loadingKey={props.loadingKey}
        active={loaderActive}
      >
        <StyledForm
          noValidate
          ref={ref}
          className={clsx(props.className, {
            grow: props.grow
          })}
          onSubmit={handleSubmit}
          onChange={handleChange}
        >
          {props.children}
        </StyledForm>
      </Loader>
    </FormProvider>
  );
}

export default forwardRef(Form);
