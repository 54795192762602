import type { InputChangeDetails } from "@9amhealth/wcl";
import type { FC } from "react";
import React, { useCallback, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import getErrorForField from "src/lib/getErrorForField";
import QuestionnaireCubit from "src/state/QuestionnaireCubit/QuestionnaireCubit";
import type { QuestionnaireField } from "src/state/QuestionnaireStepCubit/QuestionnaireStepCubit";
import { useBloc } from "src/state/state";
import OnEvent from "src/ui/components/OnEvent/OnEvent";
import ErrorBox from "src/ui/components/StyledComponents/ErrorBox";
import Translate from "src/ui/components/Translate/Translate";

const YesNoInput: FC<{
  required: boolean;
  field: QuestionnaireField;
}> = ({ required, field }) => {
  const [, { saveValue }] = useBloc(QuestionnaireCubit);
  const [selected, setSelected] = useState<"No" | "Yes" | null>(null);
  const {
    getValues,
    setValue,
    register,
    formState: { errors },
    clearErrors
  } = useFormContext();

  const { error } = getErrorForField({
    name: field.id,
    errors,
    labelText: ""
  });

  register(field.id, { required });

  useEffect(() => {
    const values = getValues();
    const value = values[field.id] as string;
    setSelected(value as "No" | "Yes" | null);
  }, []);

  const handleChange = useCallback(
    (event: CustomEvent<InputChangeDetails>): void => {
      clearErrors();
      const { value } = event.detail;
      const x = value === "Yes";
      setSelected(x ? "Yes" : "No");
      setValue(field.id, value);
      saveValue(field.id, value);
    },
    []
  );

  return (
    <div>
      <OnEvent
        events={{
          nineInputChange: handleChange
        }}
      >
        <nine-answer-item-list direction="horizontal">
          <nine-answer-item
            checked={selected === "Yes" ? "true" : "false"}
            value="Yes"
            name={field.id}
          >
            <strong>
              <Translate msg="yes" />
            </strong>
          </nine-answer-item>
          <nine-answer-item
            checked={selected === "No" ? "true" : "false"}
            value="No"
            name={field.id}
          >
            <strong>
              <Translate msg="no" />
            </strong>
          </nine-answer-item>
        </nine-answer-item-list>
      </OnEvent>
      {error && (
        <>
          <ErrorBox style={{ marginTop: "1rem" }} data-severity="error">
            <Translate msg="question_required_select" />
          </ErrorBox>
        </>
      )}
    </div>
  );
};

export default YesNoInput;
