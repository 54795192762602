import type { NineInput } from "@9amhealth/wcl";
import type { FC } from "react";
import React, { useCallback, useEffect, useRef } from "react";
import { useFormContext } from "react-hook-form";
import Translate from "src/ui/components/Translate/Translate";

interface Props {
  children?: React.ReactNode;
}

const FormConnect: FC<Props> = ({ children }) => {
  const refEl = useRef<HTMLDivElement>(null);
  const submitEl = useRef<HTMLButtonElement>(null);
  const formContext = useFormContext();

  const submitForm = useCallback(() => {
    submitEl.current?.click();
  }, []);

  const handleInputRegister = useCallback(
    (
      event: CustomEvent<{
        name: string;
        value: string;
        required: boolean;
        root: NineInput;
      }>
    ) => {
      const details = event.detail;

      formContext.register(details.name, {
        value: details.value,
        required: details.required
      });
    },
    [formContext]
  );

  const handleInputChange = useCallback(
    (
      event: CustomEvent<{
        name: string;
        value: string;
        root: NineInput;
      }>
    ) => {
      const details = event.detail;
      formContext.setValue(details.name, details.value);
    },
    [formContext]
  );

  useEffect(() => {
    const el = refEl.current;
    if (!el) return;

    el.addEventListener("nineFunnelStepSubmit", submitForm);
    el.addEventListener(
      "nineInputRegister",
      handleInputRegister as EventListener
    );
    el.addEventListener("nineInputChange", handleInputChange as EventListener);

    return () => {
      el.removeEventListener("nineFunnelStepSubmit", submitForm);
      el.removeEventListener(
        "nineInputRegister",
        handleInputRegister as EventListener
      );
      el.removeEventListener(
        "nineInputChange",
        handleInputChange as EventListener
      );
    };
  }, []);

  return (
    <div ref={refEl}>
      {children}
      <button
        type="submit"
        aria-hidden
        ref={submitEl}
        style={{ position: "fixed", left: -9999, top: -9999, zIndex: -5000 }}
      >
        <Translate msg="submit" />
      </button>
    </div>
  );
};

export default FormConnect;
