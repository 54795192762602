import { getRegexMatchResultText } from "src/lib/getRegexMatchResultText";
import { removeQuestionnaireKeywordsFromText } from "src/lib/removeQuestionnaireKeywordsFromText";
import { CustomFieldPropertyRegex } from "src/state/QuestionnaireStepCubit/CustomFields";
import type { QuestionnaireSelectChoice } from "src/state/QuestionnaireStepCubit/QuestionnaireStepCubit";
import type { TranslationKey } from "src/types/translationKey";
import type { QuestionnaireParsedSelectChoice } from "src/ui/components/MultipleTextField/MultipleTextField";

export const parseSubField = (
  choices: QuestionnaireSelectChoice[] | undefined
): QuestionnaireParsedSelectChoice[] => {
  return (
    choices?.map((choice) => {
      const label =
        getRegexMatchResultText(
          CustomFieldPropertyRegex.LABEL.exec(choice.label)
        ) ?? removeQuestionnaireKeywordsFromText(choice.label);

      const key =
        getRegexMatchResultText(
          CustomFieldPropertyRegex.DATA_KEY.exec(choice.label)
        ) ?? label;

      return {
        ...choice,
        label: label as TranslationKey,
        key,
        placeholder: getRegexMatchResultText(
          CustomFieldPropertyRegex.PLACEHOLDER.exec(choice.label)
        ),
        required: Boolean(CustomFieldPropertyRegex.REQUIRED.exec(choice.label))
      } satisfies QuestionnaireParsedSelectChoice;
    }) ?? []
  );
};
