import { NineInput } from "@9amhealth/wcl";
import styled from "@emotion/styled";
import type { FC } from "react";
import React, { useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import getErrorForField, { FieldErrorType } from "src/lib/getErrorForField";
import parseNumber from "src/lib/parseNumber";
import t from "src/lib/translate";
import QuestionnaireCubit from "src/state/QuestionnaireCubit/QuestionnaireCubit";
import {
  TIME_CODE_TEXT,
  TimeCode
} from "src/state/QuestionnaireCubit/QuestionnaireState";
import type { QuestionnaireField } from "src/state/QuestionnaireStepCubit/QuestionnaireStepCubit";
import { useBloc } from "src/state/state";
import OnEvent from "src/ui/components/OnEvent/OnEvent";
import QuestionnaireTextInput from "src/ui/components/QuestionnaireTextInput/QuestionnaireTextInput";
import Translate from "src/ui/components/Translate/Translate";
import { createUseValueRegex } from "./helpers/createUseValueRegex";
import type { TranslationKey } from "src/types/translationKey";

const Wrapper = styled.div`
  margin: 0 0 1em;
  display: grid;
  grid-template-columns: repeat(auto-fit, 1fr);
  gap: 1em;
`;

const InputFrame = styled.div``;

const InputWrap = styled.div`
  flex: 1;
`;

const ErrorMessage = styled.div`
  color: red;
  margin: 10px 0 0;
`;

export interface MedicationInputSchema {
  code: TimeCode;
  required?: boolean;
}

export const defaultFieldSchema: MedicationInputSchema[] = [
  { code: TimeCode.MORN },
  { code: TimeCode.NOON },
  { code: TimeCode.EVE },
  { code: TimeCode.NIGHT }
];

const MedicationInput: FC<{
  field: QuestionnaireField;
}> = ({ field }) => {
  const name = field.id;

  const customSchema = useMemo<MedicationInputSchema[]>(() => {
    const customTimes = field.properties?.medication_field_times ?? "";
    if (!customTimes) return defaultFieldSchema;
    const asList = customTimes.split(",").map((time) => time.trim());
    // filter out invalid ones, and make them unique
    const uniqueList = [...new Set(asList)].filter((time) =>
      Object.values(TimeCode).includes(time as TimeCode)
    );

    const times: MedicationInputSchema[] = uniqueList.map((time) => {
      const valid = Object.values(TimeCode).includes(time);
      const schemaItem: MedicationInputSchema = { code: TimeCode.VOID };
      if (!valid) {
        // eslint-disable-next-line no-console
        console.error(`Invalid time code: ${time}`);
      } else {
        schemaItem.code = time as TimeCode;
      }

      return schemaItem;
    });

    return times;
  }, [field.properties]);

  const customUnitsForInput = useMemo<string[]>(() => {
    const customUnits = field.properties?.medication_field_units ?? "";
    if (!customUnits) return [];
    const asList = customUnits.split(",").map((unit) => unit.trim());
    let lastDefined = "";
    // make sure list is same long as customSchema
    const unitForEachSchemaItem = customSchema.map((_, index) => {
      const unit = asList[index];
      if (!unit) return lastDefined;
      lastDefined = unit;
      return unit;
    });

    return unitForEachSchemaItem;
  }, [field.properties, customSchema]);

  const [, { parseAnswerOnlyValue, customFormVariables }] =
    useBloc(QuestionnaireCubit);

  const {
    getValues,
    formState: { errors },
    setError,
    clearErrors
  } = useFormContext();

  const { errorMessage } = getErrorForField({
    name,
    errors,
    labelText: field.properties?.input_label ?? ""
  });

  const maximumDailyDosage = field.properties?.max_daily_dosage ?? 0;
  const targetQuestionReference = field.properties?.question_reference ?? "";
  const singleDosage = field.properties?.dosage ?? 0;
  const setUserDosage = field.properties?.setUserDosage;
  const endAdornment = field.properties?.end_adornment ?? t("pill_other");

  const setEnteredDosage = (currentEnteredDosage: number): void => {
    if (setUserDosage && currentEnteredDosage)
      customFormVariables[`user_dosage_${setUserDosage}`] =
        `${currentEnteredDosage}`;
  };

  const chosenDosage: number = useMemo(() => {
    const refQuestionValue =
      parseAnswerOnlyValue(targetQuestionReference).value?.toString() ?? "";

    if (field.properties?.use_value && targetQuestionReference) {
      const rx = createUseValueRegex(field.properties.use_value);
      return parseFloat(rx.exec(refQuestionValue)?.[1] ?? "0");
    }

    return singleDosage || parseFloat(refQuestionValue);
  }, []);

  const handleFieldValidation = (): void => {
    const valueList = Object.values(
      (getValues(name) ?? {}) as Record<string, number>
    ).filter(Boolean);
    const countPills = valueList.reduce((x, y) => Number(x) + Number(y), 0);
    const currentEnteredDosage = countPills * chosenDosage;

    if (maximumDailyDosage && chosenDosage) {
      if (currentEnteredDosage > maximumDailyDosage) {
        setTimeout(() => {
          setError(name, {
            type: FieldErrorType.Validation,
            message: t(
              singleDosage
                ? "error_maximum_dose_error_no_dosage_question"
                : "error_maximum_dose_error",
              { dose: maximumDailyDosage }
            )
          });
        });
      } else {
        clearErrors(name);
        setEnteredDosage(currentEnteredDosage);
      }
    }
  };

  const onChangeHandle = (): void => {
    handleFieldValidation();
  };

  useEffect(handleFieldValidation, []);

  return (
    <OnEvent
      events={{
        [NineInput.customEvents.change]: onChangeHandle
      }}
    >
      <div>
        <Wrapper>
          {customSchema.map((input, i) => (
            <InputFrame key={input.code}>
              <InputWrap>
                <QuestionnaireTextInput
                  onChange={onChangeHandle}
                  required={input.required}
                  name={`${name}.${input.code}`}
                  selectOnFocus
                  placeholder="0.0"
                  label={TIME_CODE_TEXT[input.code]}
                  autoCorrect="none"
                  autoCapitalize="none"
                  parseValue={parseNumber}
                  type="text"
                  inputMode="decimal"
                  maxLength="5"
                  hideError
                  endAdornment={customUnitsForInput[i] ?? endAdornment}
                />
              </InputWrap>
            </InputFrame>
          ))}
        </Wrapper>
        <ErrorMessage>
          <Translate msg={errorMessage as TranslationKey} />
        </ErrorMessage>
      </div>
    </OnEvent>
  );
};

export default MedicationInput;
