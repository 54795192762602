import { isValidPhoneNumber } from "libphonenumber-js";

export interface InputPattern {
  value?: RegExp;
  validate?: (value: string) => boolean;
  message: string;
}

export const numberPattern: InputPattern = {
  value: /^[0-9]*[1-9][0-9]*$/,
  message: "invalid_number"
};

export const decimalPattern: InputPattern = {
  value: /^\d*[,.]?\d*$/,
  message: "invalid_number"
};

export const medicationDosage: InputPattern = {
  value: /^\d*[,.]?[5]*$/,
  message: "invalid_number"
};

export const emailPattern: InputPattern = {
  value: /\S+@\S+\.\S+/,
  message: "invalid_email"
};

export const verificationCodePattern: InputPattern = {
  value: /^\d{6}$/,
  message: "invalid_verification_code"
};

export const passwordPattern: InputPattern = {
  value: /^(.{12,128})$/,
  message: "invalid_password"
};

export const zipCodePattern: InputPattern = {
  value: /^[0-9]{5}$/,
  message: "invalid_zip_code"
};

export const phoneNumberPattern: InputPattern = {
  validate: (value: string): boolean => {
    return isValidPhoneNumber(value, "US");
  },
  message: "invalid_phone_number"
};

export const onlyTextPattern: InputPattern = {
  value: /^[a-zA-Z\- ]+$/,
  message: "invalid_text_field"
};
