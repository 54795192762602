import styled from "@emotion/styled";
import type { FC } from "react";
import React, { useCallback, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { APP_BREAKPOINT } from "src/constants/layout";
import parseInputData from "src/lib/parseInputData";
import type { QuestionnaireValue } from "src/state/QuestionnaireCubit/QuestionnaireCubit";
import QuestionnaireCubit from "src/state/QuestionnaireCubit/QuestionnaireCubit";
import type {
  QuestionnaireField,
  QuestionnaireSelectChoice
} from "src/state/QuestionnaireStepCubit/QuestionnaireStepCubit";
import { useBloc } from "src/state/state";
import FormInput from "src/ui/components/FormInput/FormInput";
import { parseSubField } from "src/ui/components/MultipleTextField/lib/parseSubField";

const Wrapper = styled.div<{ columnsCount: number }>`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: ${APP_BREAKPOINT}px) {
    display: grid;
    grid-template-columns: ${({ columnsCount }): string =>
      `repeat(${columnsCount}, 1fr)`};
    gap: 1em;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 1.6rem;

  &:last-of-type {
    margin: 0;
  }

  @media screen and (min-width: ${APP_BREAKPOINT}px) {
    margin: 0;
  }

  & span {
    font-weight: 700;
  }
`;

export interface QuestionnaireParsedSelectChoice
  extends QuestionnaireSelectChoice {
  placeholder?: string;
  required: boolean;
  key: string;
}

const MultipleTextField: FC<{
  field: QuestionnaireField;
}> = ({ field }) => {
  const name = field.id;
  const subFields: QuestionnaireParsedSelectChoice[] = parseSubField(
    field.properties?.choices
  );

  const [, { saveValue, customFormVariables }] = useBloc(QuestionnaireCubit);
  const { setValue, getValues } = useFormContext();

  const setValueEverywhere = useCallback((key: string, value: string) => {
    let parsedValue = value;

    const isNameField = key.endsWith("name");
    if (isNameField) {
      parsedValue = parseInputData("name", value);
    }

    setValue(key, parsedValue);
    saveValue(key, parsedValue);
  }, []);

  const setVariable = (key: string, targetValue: QuestionnaireValue): void => {
    customFormVariables[`input_value_${key}`] = targetValue?.toString() ?? "";
  };

  const onChangeHandle = useCallback(
    (fn: CustomEvent<{ name: string; value: string }>, key: string): void => {
      const targetValue = fn.detail.value;
      const targetName = fn.detail.name;
      const trimmedValue = targetValue.trim();

      setVariable(key, trimmedValue);
      setValueEverywhere(targetName, trimmedValue);
    },
    []
  );

  useEffect(() => {
    const valueList = Object.entries(
      (getValues(name) ?? {}) as Record<string, string>
    );

    valueList.forEach(([key, value]) => {
      setVariable(key, value);
    });
  }, []);

  const columns = subFields.length > 2 ? 2 : subFields.length;

  return (
    <Wrapper columnsCount={columns}>
      {subFields.map((option) => {
        const { label, required, placeholder, key } = option;

        return (
          <InputWrapper key={key}>
            <FormInput
              key={key}
              onChange={(e): void => onChangeHandle(e, key)}
              type="text"
              selectOnFocus
              label={label}
              placeholder={placeholder}
              required={required}
              name={`${name}.${key}`}
            />
          </InputWrapper>
        );
      })}
    </Wrapper>
  );
};

export default MultipleTextField;
