import type { InputChangeDetails } from "@9amhealth/wcl";
import type { FC } from "react";
import React, { useCallback } from "react";
import { useFormContext } from "react-hook-form";
import QuestionnaireCubit from "src/state/QuestionnaireCubit/QuestionnaireCubit";
import { useBloc } from "src/state/state";
import type { FormInputProps } from "src/ui/components/FormInput/FormInput";
import FormInput from "src/ui/components/FormInput/FormInput";

const QuestionnaireTextInput: FC<FormInputProps> = (props) => {
  const [, { saveValue }] = useBloc(QuestionnaireCubit);

  const {
    setError,
    formState: { errors }
  } = useFormContext();

  const handleChange = useCallback(
    (event: CustomEvent<InputChangeDetails>) => {
      props.onChange?.(event);

      const { name } = event.detail;
      const { value } = event.detail;
      const parsedValue = props.parseValue ? props.parseValue(value) : value;
      const trimmedValue = parsedValue.trim();
      const validateError = event.detail.root?.validate(false);

      setTimeout(() => {
        if (!validateError) {
          saveValue(name, trimmedValue);
        } else {
          // clear value
          saveValue(name, "");
        }
        if (validateError) {
          setError(name, {
            type: "validate",
            message: validateError
          });
        }
      });
    },
    [errors]
  );

  return <FormInput {...props} onChange={handleChange} />;
};

export default QuestionnaireTextInput;
