import type { InputChangeDetails } from "@9amhealth/wcl";
import type { FC, ReactElement, ReactNode } from "react";
import React, { useCallback, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import type { InputPattern } from "src/constants/patterns";

import getErrorForField from "src/lib/getErrorForField";
import t from "src/lib/translate";
import type { TranslationKey } from "src/types/translationKey";
import OnEvent from "src/ui/components/OnEvent/OnEvent";

export interface FormInputProps {
  name: string;
  required?: boolean;
  input?: "text" | "textarea";
  label?: TranslationKey;
  type?: "text" | "password" | "email" | "tel" | "number";
  decimal?: boolean;
  multiline?: boolean;
  pattern?: InputPattern;
  variant?: "filled" | "outlined" | "standard";
  onBlur?: (e: CustomEvent<InputChangeDetails>) => unknown;
  onChange?: (e: CustomEvent<InputChangeDetails>) => unknown;
  // inputProps?: (e:CustomEvent) => void;
  onFocus?: (e: CustomEvent<InputChangeDetails>) => unknown;
  defaultHelperText?: string;
  selectOnFocus?: boolean;
  hideError?: boolean;
  parseValue?: (value: string) => string;
  value?: number | string;
  loadDefault?: boolean;
  placeholder?: string;
  autoCorrect?: string;
  autoCapitalize?: string;
  endAdornment?: ReactNode;
  inputMode?: string;
  maxLength?: string;
  colGap2?: boolean;
  size?: "full" | "half";
  mask?: string;
}

const FormInput: FC<FormInputProps> = (props): ReactElement => {
  const {
    input,
    name,
    label,
    pattern,
    required,
    defaultHelperText,
    hideError,
    parseValue,
    endAdornment,
    inputMode,
    maxLength,
    mask
  } = props;
  const labelText = label ? t(label) : undefined;
  const {
    register,
    getValues,
    setValue,
    formState: { errors }
  } = useFormContext();

  const value = (getValues(name) ?? "") as number | string;
  const [inputValue, setInputValue] = useState(value);

  const { error, errorMessage } = getErrorForField({
    name,
    errors,
    labelText: labelText ?? ""
  });

  const registerProps = register(name, {
    required,
    pattern: pattern as undefined
  });

  const handleChange = useCallback(
    (e: CustomEvent<InputChangeDetails>) => {
      let targetValue: number | string = e.detail.value;

      if (parseValue) {
        targetValue = parseValue(targetValue);
      }

      setValue(name, targetValue);
      setInputValue(targetValue);
      props.onChange?.(e);
      void registerProps.onChange(e);
    },
    [name, value]
  );

  const handleBlur = useCallback(
    (e: CustomEvent<InputChangeDetails>) => {
      props.onBlur?.(e);
      void registerProps.onBlur(e);
      handleChange(e);
    },
    [name]
  );

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  useEffect(() => {
    setInputValue((getValues(name) ?? "") as number | string);
  }, []);

  return (
    <OnEvent
      events={{
        nineInputChange: handleChange,
        nineInputBlur: handleBlur
      }}
    >
      <nine-input
        scroll-into-view-on-focus
        input={input}
        label={labelText}
        help={defaultHelperText}
        error={!hideError && Boolean(error) ? errorMessage : undefined}
        value={`${inputValue}`}
        required={required ? "true" : "false"}
        name={name}
        placeholder={props.placeholder}
        autoCapitalize={props.autoCapitalize}
        autoCorrect={props.autoCorrect}
        inputMode={inputMode}
        maxLength={maxLength}
        colGap2={props.colGap2 ? "true" : "false"}
        size={props.size}
        mask={mask}
        type={props.type}
        showPasswordHelp="false"
      >
        {endAdornment ? <div slot="decoration-end">{endAdornment}</div> : null}
      </nine-input>
    </OnEvent>
  );
};

export default FormInput;
